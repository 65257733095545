import { createHead } from '@unhead/vue'
import { ComponentDescription } from '~/core/types'
import { PROPERTY_NAMES } from '~/core/constants'
import { vuetify } from '~/core/plugins'
import Renderer from '~/core/modules/Renderer'
import { buildApp } from '~/core/modules/Renderer/functions'

class ClientRenderer extends Renderer {
  async _renderInTemplateComponent({ selector, rootComponent }: ComponentDescription) {
    const { component, createStore } = rootComponent!

    document.querySelectorAll(selector!).forEach(mountEl => {
      buildApp(component, {}, [createStore(), vuetify]).mount(mountEl)
    })
  }

  async _renderInTemplate() {
    const { pageDescription, device } = this.options
    const { componentDescriptionDevices } = pageDescription
    const componentDescriptions = componentDescriptionDevices[device]

    await Promise.all(componentDescriptions.map(
      componentDescription => this._renderInTemplateComponent(componentDescription),
    ))

    delete window[PROPERTY_NAMES.initialState as any]
  }

  async _renderFull() {
    const { pageDescription, device } = this.options
    const { componentDescriptionDevices } = pageDescription
    const componentDescriptions = componentDescriptionDevices[device]
    const { selector, rootComponent } = componentDescriptions[0]
    const { component, createStore } = rootComponent!

    document.querySelectorAll(selector!).forEach(mountEl => {
      buildApp(component, {}, [createStore(), vuetify, createHead()]).mount(mountEl)
    })

    delete window[PROPERTY_NAMES.initialState as any]
  }
}

export default ClientRenderer
