import { Component, createSSRApp } from 'vue'
import { Props } from '~/core/types'

const buildApp = (component: Component, props: Props, plugins: any[]) => {
  const app = createSSRApp(component, props)

  plugins.forEach(plugin => app.use(plugin))

  return app
}

export default buildApp
