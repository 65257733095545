import { createStore } from 'vuex'
import { type Component, defineComponent, h } from 'vue'
import { PROPERTY_NAMES } from '~/core/constants'

type Props = { [prop: string]: any }

const isServer = typeof window === 'undefined'
const camelize = (s: string) => s.replace(/-./g, x => x[1].toUpperCase())

const createRootComponent = (component: Component) => {
  const result: {
    component: Component,
    createStore: () => ReturnType<typeof createStore>,
  } = {
    component: defineComponent({
      inheritAttrs: false,
      created() {
        if (isServer) {
          this.initServer()
        } else {
          this.initClient()
        }
      },
      methods: {
        initServer() {
          Object.entries(this.$attrs || {}).forEach(([attr, value]) => {
            this.$store.state.rootProps[camelize(attr)] = value
          })
        },
        initClient() {
          const key = this.$el.parentElement.dataset.vueKey
          const initialState = window[PROPERTY_NAMES.initialState as any][key]

          if (initialState) {
            this.$store.replaceState(initialState)
          }
        },
      },
      render() {
        return h(component, this.$store.state.rootProps)
      },
    }),
    createStore: () => createStore({
      state: () => ({
        rootProps: Object.entries(
          (component as Component & { props: Props }).props || {},
        ).reduce(
          (rootProps: Props, [componentPropName, { default: defaultPropValue }]) => {
            rootProps[componentPropName] = defaultPropValue
            return rootProps
          },
          {},
        ),
      }),
    }),
  }

  return result
}

export default createRootComponent
