import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { ru } from 'vuetify/locale'
// @ts-ignore-next-line
import { VClassIcon } from 'vuetify/lib/composables/icons.mjs'
import styleVars from '~/styles/variables-export.module.scss'

export default createVuetify({
  ssr: true,
  locale: {
    locale: 'ru',
    messages: {
      ru,
    },
  },
  theme: {
    themes: {
      light: {
        colors: { ...styleVars },
      },
    },
  },
  icons: {
    defaultSet: 'ui-icon',
    aliases: {},
    sets: {
      'ui-icon': {
        component: VClassIcon,
      },
    },
  },
})
