import type { DjangoContext } from '%/types'
import {
  Device,
  PageDescription,
  Props,
  RenderType,
} from '~/core/types'
import { getDefaultSelector } from './functions'

type Options = {
  pageName?: string
  pageDescription: PageDescription
  device: Device
  template?: string
  props?: Props & { context: DjangoContext }
}

abstract class Renderer {
  public constructor(private type: RenderType, protected options: Options) {
    this._defineComponentDescriptionDevices()
  }

  async render() {
    if (this.type === 'in-template') {
      await this._renderInTemplate()
    }

    if (this.type === 'full') {
      await this._renderFull()
    }

    return this
  }

  _defineComponentDescriptionDevices() {
    const { pageDescription: { componentDescriptionDevices } } = this.options

    Object.values(componentDescriptionDevices).forEach(componentDescriptions => {
      componentDescriptions.forEach(componentDescription => {
        componentDescription.location = componentDescription.page ? `pages/${componentDescription.page}` : 'common'

        if (!componentDescription.selector) {
          componentDescription.selector = getDefaultSelector(componentDescription.name)
        }
      })
    })
  }

  abstract _renderInTemplate(): Promise<void>

  abstract _renderFull(): Promise<void>
}

export default Renderer
